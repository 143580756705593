import React from "react"
import styles from "./productDetail.module.scss"
import { Link } from "gatsby"
import Menubar from "../components/menubar/menubarEng"
import ProjectTopImage from "../img/proje_975.jpg"
import PDMiddle from "../components/pDmiddle/pDmiddle"
import Footer from "../components/footer/footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import PD01 from "../img/PD01.jpg"
import PD02 from "../img/PD02.jpg"
import PD03 from "../img/PD03.jpg"

export default () => (
  <div className={styles.productDetailContainer}>
    <div className={styles.leftE}></div>
    <div className={styles.centerSite}>
      <Menubar />
      <div className={styles.imageTopProductDetailContainer}>
        <img
          src={ProjectTopImage}
          alt="projects"
          className={styles.imageTopProductDetail}
        />
        <div className={styles.verticalLineWhiteProductDetail}>
          <div className={styles.socialMediaIcons}>
            <a
              href="https://www.facebook.com/atiaahsap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size="1x"
                className={styles.ffIcon}
              />
            </a>
            <br />
            <a
              href="https://www.instagram.com/atiaahsap/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="1x"
                className={styles.igIcon}
              />
            </a>
          </div>
        </div>
        <span className={styles.topSpanProductDetail}>
          MY <br />
          RESIDENCE
          <br />
          <Link to="/projects" className={styles.backLinkPD}>
            ALL PROJECTS <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </span>
      </div>
      <div className={styles.pdContainer}>
        <PDMiddle
          info="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam recusandae repellendus soluta, minima consequatur, ut assumenda, omnis neque officiis corporis facilis totam? Saepe, blanditiis reprehenderit voluptatibus rem dolorum doloribus nihil ullam nobis."
          imageSrc1={PD01}
          imageSrc2={PD02}
          imageSrc3={PD03}
        />
      </div>
      <Footer />
    </div>
    <div className={styles.rightE}></div>
  </div>
)
