import React from "react"
import Carousel from "react-bootstrap/Carousel"
import styles from "./pDmiddle.module.scss"

export default props => (
  <div className={styles.pDmiddleContainer}>
    <Carousel indicators={false} fade={true} id="pdetailCar">
      <Carousel.Item>
        <img src={props.imageSrc1} alt="first of item detail" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={props.imageSrc2} alt="second of item detail" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={props.imageSrc3} alt="third of item detail" />
      </Carousel.Item>
    </Carousel>
    <div className={styles.PDMiddleTextBox}>
      <p>{props.info}</p>
    </div>
  </div>
)
